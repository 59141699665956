body { background-color: #ffffff; }
body { color: var(--clr-6738); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-6740);
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-6740);
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-6740);
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-6740);
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-6741);
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-6740);
font-family: 'Archivo Black';
font-weight: normal;
font-style: normal;
line-height: 1;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
.button {
font-family: 'Roboto';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-6740);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
@media #{$large-up} {
font-size: 30px;

}
}
summary {
font-family: 'Roboto';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-6739);}
/* Primary:3 */
.MES3 {
background-color: var(--clr-6742);
color: #ffffff;
 }
/* Primary:4 */
.MES4 {
background-color: var(--clr-6742);
color: #ffffff;
 }
.MES4 {
background-color: var(--clr-6742);
color: #ffffff;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: #ffffff;
 }
 }
cite.MEC4{
color: #ffffff;
}
/* Secondary:5 */
.MES5 {
background-color: var(--clr-6740);
color: #ffffff;
 }
/* Secondary:6 */
.MES6 {
background-color: var(--clr-6740);
color: #ffffff;
 }
.MES6 {
background-color: var(--clr-6740);
color: #ffffff;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: #ffffff;
 }
 }
a.MEC6 { color: var(--clr-6743);
&:hover { color: var(--clr-6744);}
 }
cite.MEC6{
color: #ffffff;
}
/* Dark:7 */
.MES7 {
background-color: var(--clr-6738);
color: #ffffff;
 }
/* Dark:8 */
.MES8 {
background-color: var(--clr-6745);
color: #ffffff;
 }
.MES8 {
background-color: var(--clr-6745);
color: #ffffff;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: #ffffff;
 }
 }
a.MEC8 { color: var(--clr-6746);
&:hover { color: var(--clr-6740);}
 }
cite.MEC8{
color: #ffffff;
}
/* Light:9 */
.MES9 {
background-color: var(--clr-6746);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-6746);
 }
.MES10 {
background-color: var(--clr-6746);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-6743);
color: var(--clr-6738);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-6743);
color: var(--clr-6738);
padding: 15px;

border-width: 0 1px 1px 0;
border-style: solid;
border-color: #ffffff;
 }
.MES12 {
background-color: var(--clr-6743);
color: var(--clr-6738);
padding: 15px;

border-width: 0 1px 1px 0;
border-style: solid;
border-color: #ffffff;
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: var(--clr-6738);
 }
 }
cite.MEC12{
color: var(--clr-6738);
}
/* Menu:13 */
.MES13 {
background-color: var(--clr-6747);
color: var(--clr-6738);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
 }
.MES13 {
background-color: var(--clr-6747);
color: var(--clr-6738);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
min-height:60px;
@media #{$medium-up} {
min-height: 80px;};
@media #{$large-up} {
min-height: 80px;};
 }
a.MEC13 { color: #ffffff;
&:hover { color: var(--clr-6746);}
 }
cite.MEC13{
color: var(--clr-6738);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
}
/* home block blue:14 */
.MES14 {
background-color: var(--clr-6740);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ellapack.com.au/img/538/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES14 {
background-color: var(--clr-6740);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ellapack.com.au/img/538/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
.MEC14 li {color: #ffffff;}
cite.MEC14{
color: #ffffff;
}
/* :15 */
.MES15 {
background-color: #ffffff;
color: var(--clr-6738);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-6740) transparent transparent transparent;
 }
.MES15 {
background-color: #ffffff;
color: var(--clr-6738);
border-width: 5px 0 0 0;
border-style: solid;
border-color: var(--clr-6740) transparent transparent transparent;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: var(--clr-6738);
 }
 }
.MEC15 li {color: var(--clr-6738-flat);}
cite.MEC15{
color: var(--clr-6738);
}
/* Hollaw:16 */
.MES16 {
color: #ffffff;
&:hover { color: #ffffff;}
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* :17 */
.MES17 {
& .slider-arrow {color: var(--clr-6748);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 100px;
};
} }
/* Contents:18 */
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-6738);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
 }
.MES18 {
background-color: #ffffff;
&:hover, &.hover { background-color: #ffffff;}
color: var(--clr-6738);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
 }
a.MEC18 { color: var(--clr-6738);
&:hover { color: var(--clr-6738);}
 }
cite.MEC18{
color: var(--clr-6738);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #ffffff;
 }
h1.MEC19 { @media #{$medium-up} { font-size: 38.5px; }; }
h1.MEC19 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC19 { @media #{$medium-up} { font-size: 33px; }; }
h2.MEC19 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC19 { @media #{$medium-up} { font-size: 30.8px; }; }
h3.MEC19 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC19 { @media #{$medium-up} { font-size: 27.5px; }; }
h4.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC19 { @media #{$medium-up} { font-size: 22px; }; }
h5.MEC19 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC19 { @media #{$medium-up} { font-size: 44px; }; }
h6.MEC19 { @media #{$large-up} { font-size: 48px; }; }
 }
/* Footer Dark:20 */
.MES20 {
background-color: var(--clr-6745);
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
 }
.MES20 {
background-color: var(--clr-6745);
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
a.MEC20 { color: var(--clr-6746);
&:hover { color: var(--clr-6740);}
 }
cite.MEC20{
color: #ffffff;
font-size: 11.2px;
@media #{$medium-up} {
font-size: 12.8px;
};
}
/* Prod grid:21 */
.MES21 {
background-color: #ffffff;
color: var(--clr-6738);
border-width: 1px;
border-style: solid;
border-color: #f2f2f2 var(--clr-6746);
 }
.MES21 {
background-color: #ffffff;
color: var(--clr-6738);
border-width: 1px;
border-style: solid;
border-color: #f2f2f2 var(--clr-6746);
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: var(--clr-6745);
 }
 }
cite.MEC21{
color: var(--clr-6738);
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: var(--clr-6746);
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: var(--clr-6742);
}
color: #ffffff;
& > article { color: #ffffff;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
} }
/* Secondary:24 */
.MES24 {
color: #ffffff;
border-radius: 30px;
border-width: 2px;
border-style: solid;
border-color: #ffffff;
 }
/* Menu Menu:25 */
nav.me-Menu.MES25 {
& .menu-item.MEC25, & .menu-item.MEC25 > div.MEC25{ & > a.MEC25{color: #ffffff;
font-size: 14px;
text-transform: uppercase;
}
 &:hover > a.MEC25{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC25 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC25 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC25 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC25 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Product Menu:26 */
nav.me-Menu.MES26 {
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: #ffffff;
font-size: 16.8px;
text-transform: uppercase;
}
 &:hover > a.MEC26{color: #ffffff;
}
 }
.menu-item.MEC26 { border-width: 5px;
border-style: solid;
border-color: transparent;
 }
&.horizontal > .menu-item.MEC26 { border:0;
border-color: transparent;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC26 { border:0;
border-color: transparent;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC26 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 20;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC26 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 5px;}



&.horizontal .menu-item.MEC26:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #00171f;}}
&.vertical .menu-item.MEC26:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #00171f;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC26{background-color: #00171f; &:hover {background-color: 27;}
}
& .menu-item.MEC26, & .menu-item.MEC26 > div.MEC26{ & > a.MEC26{color: #ffffff;
}
 &:hover > a.MEC26{color: 20;
}
 }

}
}
 }
/* LOGO Panel:28 */
.MES28 {
color: var(--clr-6740);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 16.8px;
};
 }
.MES28 {
color: var(--clr-6740);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 16.8px;
};
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: var(--clr-6742);
 }
h1.MEC28 { font-size: 30px; }
h1.MEC28 { @media #{$medium-up} { font-size: 49px; }; }
h1.MEC28 { @media #{$large-up} { font-size: 64px; }; }
h2.MEC28 { font-size: 26.4px; }
h2.MEC28 { @media #{$medium-up} { font-size: 42px; }; }
h2.MEC28 { @media #{$large-up} { font-size: 56px; }; }
h3.MEC28 { font-size: 24px; }
h3.MEC28 { @media #{$medium-up} { font-size: 39.2px; }; }
h3.MEC28 { @media #{$large-up} { font-size: 48px; }; }
h4.MEC28 { font-size: 21.6px; }
h4.MEC28 { @media #{$medium-up} { font-size: 35px; }; }
h4.MEC28 { @media #{$large-up} { font-size: 40px; }; }
h5.MEC28 { font-size: 20.4px; }
h5.MEC28 { @media #{$medium-up} { font-size: 28px; }; }
h5.MEC28 { @media #{$large-up} { font-size: 32px; }; }
h6.MEC28 { font-size: 30px; }
h6.MEC28 { @media #{$medium-up} { font-size: 56px; }; }
h6.MEC28 { @media #{$large-up} { font-size: 64px; }; }
 }
cite.MEC28{
color: var(--clr-6740);
font-size: 14px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 16.8px;
};
}
/* home block Red:29 */
.MES29 {
background-color: var(--clr-6742);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ellapack.com.au/img/538/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES29 {
background-color: var(--clr-6742);
& > .underlay, & > .inner-overlay { opacity: 0.3 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ellapack.com.au/img/538/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: #ffffff;
 }
 }
.MEC29 li {color: #ffffff;}
cite.MEC29{
color: #ffffff;
}
/* home block black:30 */
.MES30 {
background-color: var(--clr-6745);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ellapack.com.au/img/538/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES30 {
background-color: var(--clr-6745);
& > .underlay, & > .inner-overlay { opacity: 0.2 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://ellapack.com.au/img/538/64');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 300px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
.MEC30 li {color: #ffffff;}
cite.MEC30{
color: #ffffff;
}
/* Shade 3:31 */
.MES31 {
background-color: var(--clr-6744);
 }
/* Shade 3:32 */
.MES32 {
background-color: var(--clr-6744);
color: #ffffff;
padding: 0;

 }
.MES32 {
background-color: var(--clr-6744);
color: #ffffff;
padding: 0;

 }
a.MEC32 { color: #ffffff;
&:hover { color: var(--clr-6743);}
 }
cite.MEC32{
color: #ffffff;
}
/* circle:33 */
.MES33 {
background-color: var(--clr-6740);
&:hover, &.hover { background-color: var(--clr-6749);}
color: #ffffff;
padding: 15px;

 }
.MES33 {
background-color: var(--clr-6740);
&:hover, &.hover { background-color: var(--clr-6749);}
color: #ffffff;
padding: 15px;

h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
.MEC33 li {color: #ffffff;}
cite.MEC33{
color: #ffffff;
}
/* Top Menu:34 */
nav.me-Menu.MES34 {
& .menu-item.MEC34, & .menu-item.MEC34 > div.MEC34{ & > a.MEC34{color: #0d0221;
font-size: 15.4px;
@media #{$medium-up} {
font-size: 16.8px;
}@media #{$large-up} {
font-size: 18.2px;
}text-transform: uppercase;
}
 &:hover > a.MEC34{color: var(--clr-6745);
}
 }
&.horizontal > .menu-item.MEC34 { border:0;
border-color: #ffffff;
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC34 { border:0;
border-color: #ffffff;
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC34 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 20;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC34 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}


&.horizontal .menu-item.MEC34:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-6749-flat);}}
&.vertical .menu-item.MEC34:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-6749-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC34{background-color: var(--clr-6749); &:hover {background-color: 21;}
}
& .menu-item.MEC34, & .menu-item.MEC34 > div.MEC34{ & > a.MEC34{color: #ffffff;
font-size: 14px;
}
  }

}
}
 }
/* Accordion:35 */
details.MES35 {
& > summary{background-color: var(--clr-6739);
}
 }
/* Product Panel:36 */
.MES36 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-6746);}
color: var(--clr-6745);
padding: 10px;

@media #{$large-up} {
padding: 15px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-6743);
 }
.MES36 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-6746);}
color: var(--clr-6745);
padding: 10px;

@media #{$large-up} {
padding: 15px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-6743);
 }
cite.MEC36{
color: var(--clr-6745);
}
/* Quick Menu:37 */
nav.me-Menu.MES37 {
& .menu-item.MEC37, & .menu-item.MEC37 > div.MEC37{ & > a.MEC37{color: #ffffff;
font-size: 14px;
text-transform: uppercase;
}
 &:hover > a.MEC37{color: var(--clr-6745);
}
 }
&.horizontal > .menu-item.MEC37 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC37 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC37 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC37 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Slider text 2:38 */
.MES38 {
color: var(--clr-6746);
font-size: 14px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
 }
.MES38 {
color: var(--clr-6746);
font-size: 14px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
h1.MEC38 { @media #{$large-up} { font-size: 56px; }; }
h2.MEC38 { @media #{$large-up} { font-size: 49px; }; }
h3.MEC38 { @media #{$large-up} { font-size: 42px; }; }
h4.MEC38 { @media #{$large-up} { font-size: 35px; }; }
h5.MEC38 { @media #{$large-up} { font-size: 28px; }; }
h6.MEC38 { @media #{$large-up} { font-size: 56px; }; }
 }
cite.MEC38{
color: var(--clr-6746);
font-size: 14px;
@media #{$medium-up} {
font-size: 17.6px;
};
@media #{$large-up} {
font-size: 19.2px;
};
}
/* Enquiry Form:39 */
.MES39 {
background-color: var(--clr-6740);
color: #ffffff;
 }
.MES39 {
background-color: var(--clr-6740);
color: #ffffff;
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: #ffffff;
 }
 }
a.MEC39 { color: var(--clr-6743);
&:hover { color: #ffffff;}
 }
cite.MEC39{
color: #ffffff;
}
/* Slider Panel:40 */
.MES40 {
background-color: var(--clr-6750);
 }
.MES40 {
background-color: var(--clr-6750);
 }
/* Extra panel:41 */
.MES41 {
background-color: var(--clr-6744);
color: #ffffff;
padding: 0 0 20px 0;

@media #{$medium-up} {
padding: 0;

}
 }
.MES41 {
background-color: var(--clr-6744);
color: #ffffff;
padding: 0 0 20px 0;

@media #{$medium-up} {
padding: 0;

}
 }
a.MEC41 { color: #ffffff;
&:hover { color: var(--clr-6743);}
 }
cite.MEC41{
color: #ffffff;
}
/* Green panel:42 */
.MES42 {
background-color: var(--clr-6751);
color: #ffffff;
 }
.MES42 {
background-color: var(--clr-6751);
color: #ffffff;
h1.MEC42, h2.MEC42, h3.MEC42, h4.MEC42, h5.MEC42, h6.MEC42 { color: #ffffff;
 }
 }
.MEC42 li {color: #ffffff;}
cite.MEC42{
color: #ffffff;
}
/* :43 */
.MES43 {
& > hr {border-width: 1px;}
& > hr {border-color: transparent transparent #ffffff transparent;}
& > hr {border-top-style: solid;}
& > hr {border-right-style: solid;}
& > hr {border-bottom-style: solid;}
& > hr {border-left-style: solid;}
 }
/* Primary:44 */
.MES44 {
background-color: var(--clr-6751);
&:hover {background-color: #00a651c7;}
color: #ffffff;
 }
/* No Cap panel:45 */
.MES45 {
background-color: #ffffff;
&:hover, &.hover { background-color: #f2f2f2;}
padding: 10px;

@media #{$large-up} {
padding: 15px;

}
 }
.MES45 {
background-color: #ffffff;
&:hover, &.hover { background-color: #f2f2f2;}
padding: 10px;

@media #{$large-up} {
padding: 15px;

}
 }
/* RED Button:46 */
.MES46 {
background-color: var(--clr-6742);
color: #ffffff;
padding: 10px 20px;

 }
/* Green Button:48 */
.MES48 {
background-color: var(--clr-6751);
color: #ffffff;
padding: 10px 20px;

 }
/* Transparent White:49 */
.MES49 {
background-color: #ffffff93;
padding: 15px;

 }
.MES49 {
background-color: #ffffff93;
padding: 15px;

 }
/* Blue Transparent:50 */
.MES50 {
color: #ffffff;
 }
.MES50 {
color: #ffffff;
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: #ffffff;
 }
 }
cite.MEC50{
color: #ffffff;
}
/* :51 */
.MES51 {
border-radius: 20px;
 }
/* :52 */
.MES52 {
background-color: var(--clr-6739);
border-radius: 20px;
 }
/* New_Palette_1:53 */
.MES53 {
background-color: #00171f;
 }
.MES53 {
background-color: #00171f;
 }
